(<template>
  <shared-pricing class="in-person-details">
    <travel-info class="in-person-details__travel-info" />
    <slot></slot>
  </shared-pricing>
</template>)

<script>
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import TravelInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/TravelInfo';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'travel-info': TravelInfo
    }
  };
</script>
