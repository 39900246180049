(<template>
  <shared-pricing :title="title"
                  class="videoroom-details">
    <travel-info class="videoroom-details__travel-info" />
    <slot></slot>
  </shared-pricing>
</template>)

<script>
  import InterpreterInPerson from '@/components/assignment_components/one_assignment/interactive_info/pt_awarded/InterpreterInPerson';

  export default {
    extends: InterpreterInPerson
  };
</script>
